import Vue from 'vue'
import App from './App.vue'
import router from './router'

Vue.prototype.imgUrl = 'https://sabbont.gz.bcebos.com/paspages'

Vue.config.productionTip = false


// if (
//   navigator.userAgent.match(
//     /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
//   )
// ) {
//   this.$router.push('/newUrlMobile')
// } else {
//   this.$router.push('/newUrlPc')
// }

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app')
