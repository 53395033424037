import Vue from 'vue'
import VueRouter from 'vue-router'

const NewUrlPc = () => import('../views/newUrl-pc.vue')
const NewUrlMobile = () => import('../views/newUrl-mobile.vue')

Vue.use(VueRouter)

const routes = [
  {
    path: '/newUrlPc',
    component: NewUrlPc,
  },
  {
    path: '/newUrlMobile',
    component: NewUrlMobile,
  },
]

const router = new VueRouter({
  routes,
  mode: 'hash',
})

export default router
